import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utils/utility";
import AdminLogo from "../../../assets/images/logo.png";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);

  return (
    <EmailContainer style={{ padding: "10px", margin: "5px", border: "solid 2px #ccc" }}>
      <div
        style={{
          paddingBottom: "10px",
          borderBottom: "solid 1px #ccc",
          paddingTop: "10px",
          paddingLeft: "10px",
          display: "flex",
        }}
      >
        <img src={AdminLogo} alt="AdminLogo" width="100" height="60" />
        <div style={{ lineHeight: "5px", marginLeft: "10px" }}>
          <h2 style={{ color: "#da1d25" }}>Malaysia</h2>
          <h2 style={{ color: "#2a2d72" }}>Arrival Card</h2>
        </div>
      </div>

      <p style={{ fontSize: "16px" }}>
        Dear {OrderDetails?.full_name || "Customer"},
      </p>
      <p style={{ fontSize: "16px" }}>
        We would like to inform you that your Malaysia digital arrival card
        application has been successfully approved.
      </p>
      <p style={{ fontSize: "16px" }}>
        To download your Digital Arrival Card
      </p>
      <p style={{ fontSize: "16px" }}>Please click on the link below</p>
      <p style={{ fontSize: "16px" }}>
        Username:{" "}
        <a href={`mailto:${OrderDetails?.email || ""}`}>
          {OrderDetails?.email || ""}
        </a>
      </p>
      <p style={{ fontSize: "16px" }}>Order ID: {OrderDetails?.order_id}</p>
      <br />
      <p style={{ fontSize: "16px" }}>
        <Link
          target="__blank"
          to={`https://admin-malaysia-arrival.com/track-order/${encryptVal(
            OrderDetails?.order_id
          )}`}
        >
          Click Here
        </Link>
      </p>
      <br />
      <p style={{ fontSize: "16px" }}>
        <b>Malaysia Arrival Card Validity:</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        The digital arrival card is valid until the Expiration date noted in the
        document.
      </p>
      <p style={{ fontSize: "16px" }}>
        It is recommended, but not required, that you take a printed or digital
        copy of your digital arrival card with you.
      </p>
      <p style={{ fontSize: "16px" }}>
        Please make sure you check the official requirements and health
        regulations before traveling to your destination as travel restrictions
        may be applied in some cases.
      </p>
      <p style={{ fontSize: "16px" }}>
        Please contact us with any questions you may have regarding your digital
        arrival card.
      </p>
      <p style={{ fontSize: "16px" }}>
        Should you have any inquiries, please contact us through our{" "}
        <Link to="https://malaysia-arrivalcard.online/contact_us.php">
          contact form
        </Link>{" "}
        or via email at{" "}
        <a href="mailto:inquiries@malaysia-arrivalcard.online">
          inquiries@malaysia-arrivalcard.online
        </a>{" "}
        indicating your order ID.
      </p>
      <p style={{ fontSize: "16px" }}>
        <b>Customer Service Number:</b> +1 (760) 621-3781
      </p>
      <p style={{ fontSize: "16px" }}>Order ID: {OrderDetails?.order_id}</p>
      <br />
      <p style={{ fontSize: "16px" }}>
        <b>Important notice:</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at{" "}
        <a href="mailto:inquiries@malaysia-arrivalcard.online">
          inquiries@malaysia-arrivalcard.online
        </a>
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>
      <p style={{ fontSize: "16px" }}>Thank you for using our services.</p>
      <p style={{ fontSize: "16px" }}>Regards,</p>
      <p style={{ fontSize: "16px" }}>
        <b>Processing Department</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        <Link to="https://malaysia-arrivalcard.online/terms_condition.php" target="__blank">
          Terms
        </Link>{" "}
        |{" "}
        <Link to="https://malaysia-arrivalcard.online/privacy_policy.php" target="__blank">
          Privacy
        </Link>{" "}
        |{" "}
        <Link to="https://malaysia-arrivalcard.online/contact_us.php" target="__blank">
          Contact
        </Link>{" "}
        |{" "}
        <Link to="https://malaysia-arrivalcard.online/refund_policy.php" target="__blank">
          Refund
        </Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
