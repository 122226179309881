import apiService from "./ApiService";

export default class OrderService {
  static getFolderCount = () => apiService.get("admin/get-folder-count");
  static getOrdersList = ({ orderData }) =>
    apiService.get(
      `/admin/get-orders-list/${orderData.orderName}?page=${orderData.page}&limit=${orderData.perPage}&delay=1`
    );
  static deleteOrdersData = (deleteData) =>
    apiService.put(`/admin/change-order-status`, deleteData);
  static sendRefundMail = (refundData) =>
    apiService.put("/admin/move-to-complete-refund", refundData);
  static searchOrder = (data) => apiService.post(`/admin/search-order`, data);
  static moveOrderStatus = (data) =>
    apiService.put(`/admin/move-order-status`, data);
  static getOrderDetailsByOrderId = (orderId) =>
    apiService.get(`/admin/get-orders-details/${orderId}`);
  static uploadVisaDocument = (data) =>
    apiService.upload("/admin/upload-visa-document", data);
  static resendMail = (data) => apiService.post("/admin/resend-mail", data);
  static GetDefencePackPdf = (data) =>
    apiService.post("/admin/get-defence-pack-pdf", data, {
      responseType: "blob",
    });

  static defencePack = (orderId) =>
    apiService.post("/admin/defence-pack-details", orderId);
}
